import React from "react"
import styled from "styled-components"
import Hero from "../components/landing-pages/Lp3/hero"
import Seo from "../components/seo"
import HeaderLp from "../components/landing-pages/Lp3/headerLp"
import Features from "../components/landing-pages/Lp3/features"
import StickyCtaClosable from "../components/landing-pages/stickyCtaClosable"
import { useContext, useEffect, useState } from "react"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import TryPdf from "../components/shared/TryPdf"
import Cta from "../components/landing-pages/Lp3/cta"
import Showcase from "../components/landing-pages/Lp3/showcase"
import Reviews from "../components/landing-pages/Lp3/reviews"
import saleDate from "../utils/saleDate"
import SpecialOffer from "../components/landing-pages/Lp3/specialOffer"
const Main = styled.div``

const Lp1 = ({ location }) => {
  const { closedSticky, setClosedSticky } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (closedSticky == null) {
      setClosedSticky(false)
    }
  }, [])

  //global context
  const { setScroll } = useContext(GlobalAuthContext)
  const [myPopUp, setMyPopUp] = useState("hide")

  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }

  useEffect(() => {
    window.USA = true
    window.myTag = "US"
  }, [])

  return (
    <Main>
      {closedSticky == false && (
        <StickyCtaClosable
          date={`Get 50% off for a limited time! Sale ends ${saleDate()}.`}
        ></StickyCtaClosable>
      )}
      <HeaderLp
        wrapLocation={location}
        location={`lp4`}
        closedSticky={closedSticky}
        getTrial={openPopup}
      />
      <Seo title="Create &amp; Edit PDF files with PDF Pro" noindex={true} />
      <Hero location={location} getTrial={openPopup}></Hero>
      <Features />
      {/* <SpecialOffer /> */}
      <Reviews />
      <Showcase />
      <Cta location={location} />
      {myPopUp === "show" && <TryPdf close={closePopup} />}
    </Main>
  )
}

export default Lp1
